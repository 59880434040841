import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface UpgradeToPlusPopupProps {
  onUpgrade: () => void;
  onClose: () => void;
}

const UpgradeToPlusPopup: React.FC<UpgradeToPlusPopupProps> = ({
  onUpgrade,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Start the entrance animation after a short delay
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    // Delay the actual closing to allow for exit animation
    setTimeout(onClose, 300);
  };
  return (
    <CenteredPopup>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ${
          isVisible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={handleClose}
        ></div>
        <div
          className={`w-[90%] max-w-md relative transition-all duration-300 ${
            isVisible ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
          }`}
        >
          <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-custom p-6 sm:p-8 w-full relative shadow-lg">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-white text-center">
              Unlock the Power of Plus!
            </h2>
            <div className="space-y-3 sm:space-y-4 mb-4 sm:mb-6">
              <Feature icon="📋" text="Handle multiple shopping lists" />
              <Feature
                icon="🤝"
                text="Share and manage lists with family & friends"
              />
              <Feature
                icon="🤖"
                text="Use AI to categorize items and process recipes"
              />
              <Feature
                icon="💬"
                text="Enable bot functionality in group chats"
              />
              <Feature icon="⏰" text="Set shopping reminders" />
            </div>
            <p className="text-white text-center mb-4 sm:mb-6 font-medium text-sm sm:text-base px-4">
              Take your shopping routine to the next level for just 1.99$/mo!
            </p>
            <div className="flex justify-center">
              <button
                onClick={onUpgrade}
                className="px-4 sm:px-6 py-2 sm:py-3 bg-[#FF9933] text-white rounded-full font-bold text-base sm:text-lg hover:bg-[#FF8000] transition-colors shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-transform"
              >
                Upgrade to Plus
              </button>
            </div>
          </div>
        </div>
      </div>
    </CenteredPopup>
  );
};

const Feature: React.FC<{ icon: string; text: string }> = ({ icon, text }) => (
  <div className="flex items-center space-x-3 text-white">
    <span className="text-xl sm:text-2xl">{icon}</span>
    <span className="font-medium text-sm sm:text-base">{text}</span>
  </div>
);

export default UpgradeToPlusPopup;

interface CenteredPopupProps {
  children: React.ReactNode;
}

const CenteredPopup: React.FC<CenteredPopupProps> = ({ children }) => {
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    let root = document.getElementById('popup-root');
    if (!root) {
      root = document.createElement('div');
      root.id = 'popup-root';
      document.body.appendChild(root);
    }
    setPortalRoot(root);

    return () => {
      if (root && root.parentNode) {
        root.parentNode.removeChild(root);
      }
    };
  }, []);

  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none m-2">
      <div className="pointer-events-auto w-[90%] max-w-md">{children}</div>
    </div>,
    portalRoot,
  );
};
