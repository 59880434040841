import { useState, useCallback, useRef } from 'react';
import { Item } from '../types/Item';
import { Data } from '../types/Data';
import * as api from './api';

export const useAPI = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const loadingTimerRef = useRef<NodeJS.Timeout | null>(null);

  const clearLoadingTimer = useCallback(() => {
    if (loadingTimerRef.current) {
      clearTimeout(loadingTimerRef.current);
      loadingTimerRef.current = null;
    }
  }, []);

  const setLoadingWithDelay = useCallback(() => {
    clearLoadingTimer();
    loadingTimerRef.current = setTimeout(() => setIsLoading(true), 500);
  }, [clearLoadingTimer]);

  const handleApiCall = useCallback(
    async <T>(
      apiCall: () => Promise<T>,
      errorMessage: string,
    ): Promise<T | null> => {
      setLoadingWithDelay();
      setError(null);
      try {
        const result = await apiCall();
        setIsLoading(false);
        clearLoadingTimer();
        return result;
      } catch (err) {
        setError(`${errorMessage}. Please try again.`);
        setIsLoading(false);
        clearLoadingTimer();
        return null;
      }
    },
    [clearLoadingTimer, setLoadingWithDelay],
  );

  const fetchItems = useCallback(
    (listUuid: string) => {
      handleApiCall(
        () => api.fetchItems(listUuid),
        'Failed to fetch items',
      ).then((fetchedItems) => {
        if (fetchedItems) {
          setItems(fetchedItems);
        }
      });
    },
    [handleApiCall],
  );

  const addItem = useCallback(
    (item: Item, userName?: string) => {
      return handleApiCall(
        () => api.addItem(item, userName),
        'Failed to add item',
      );
    },
    [handleApiCall],
  );

  const markActivityState = useCallback(
    (itemIds: number[]) => {
      return handleApiCall(
        () => api.markActivityState(itemIds),
        'Failed to update item activity state',
      );
    },
    [handleApiCall],
  );

  const removeItems = useCallback(
    (itemIds: number[]) => {
      return handleApiCall(
        () => api.removeItems(itemIds),
        'Failed to remove items',
      );
    },
    [handleApiCall],
  );

  const fetchInitData = useCallback(
    (userId: number) => {
      return handleApiCall(
        () => api.fetchInitData(userId),
        'Failed to fetch initial data',
      );
    },
    [handleApiCall],
  );

  const createList = useCallback(
    (listName: string, accountUuid: string, userId: number) => {
      return handleApiCall(
        () => api.createList(listName, accountUuid, userId),
        'Failed to create list',
      );
    },
    [handleApiCall],
  );

  const clearItems = useCallback(() => {
    setItems([]);
    setError(null);
    setIsLoading(false);
    clearLoadingTimer();
  }, [clearLoadingTimer]);

  const setPremium = useCallback(
    (userId: number) => {
      handleApiCall(
        () => api.setPremiumClicked(userId),
        'Failed to handle premium',
      );
    },
    [handleApiCall],
  );

  return {
    items,
    isLoading,
    error,
    fetchItems,
    addItem,
    markActivityState,
    removeItems,
    fetchInitData,
    createList,
    setItems,
    clearItems,
    setPremium,
  };
};
