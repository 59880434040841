import React from 'react';
import { List } from '../../types/List';
import ListItem from './ListItem';
import AddNewListItem from './AddNewListItem';

interface ListContainerProps {
  lists: List[] | null;
  onListClick: (listUuid: string) => void;
  onAddList: (name: string) => void;
  accountType: 'free' | 'plus';
  onUpgrade: () => Promise<void>;
}

const ListContainer: React.FC<ListContainerProps> = ({
  lists,
  onListClick,
  onAddList,
  accountType,
  onUpgrade,
}) => {
  return (
    <div className="grid grid-cols-2 gap-4 w-full max-w-full overflow-x-hidden">
      {!lists || lists.length === 0 ? (
        <div className="col-span-2 flex justify-center items-center">
          <h1 className="text-xl font-light text-center mb-4 text-text-hint">
            No lists provided
          </h1>
        </div>
      ) : (
        lists.map((list, index) => (
          <div
            key={list.listUuid}
            className={`${
              index % 3 === 0 ? 'col-span-2' : 'col-span-1'
            } relative`}
          >
            <ListItem list={list} onClick={() => onListClick(list.listUuid)} />
          </div>
        ))
      )}
      <div className="col-span-2 relative">
        <AddNewListItem
          onAddList={onAddList}
          canAddNewList={accountType === 'plus' || !lists || lists.length === 0}
          onUpgrade={onUpgrade}
        />
      </div>
    </div>
  );
};

export default ListContainer;
