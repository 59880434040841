import React, { useEffect, useState } from 'react';

interface LoadingBarProps {
  items: { id: number; isActive: boolean }[];
}

const LoadingBar: React.FC<LoadingBarProps> = ({ items }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (items.length === 0) {
      setPercentage(0);
      return;
    }

    const inactiveCount = items.filter((item) => !item.isActive).length;
    const newPercentage = (inactiveCount / items.length) * 100;
    setPercentage(newPercentage);
  }, [items]);

  return (
    <div className="w-full h-6 bg-separator rounded-full overflow-hidden relative">
      <div className="absolute inset-0.5 bg-separator rounded-full overflow-hidden">
        <div
          className="h-full bg-completed transition-all duration-150 ease-in-out rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <div className="absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="text-xs font-medium text-gray-700 mix-blend-difference">
          {percentage.toFixed(1)}%
        </span>
      </div>
    </div>
  );
};

export default LoadingBar;
