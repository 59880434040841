import React, { useCallback, useEffect, useState } from 'react';
import { List } from '../types/List';
import ListContainer from './ListsPage/ListsContainer';
import { Data } from '../types/Data';
import { useAPI } from '../hooks/usseAPI';
import ProductItemList from './TaskList/ProductItemList';
import { Item } from '../types/Item';

interface MainComponentProps {
  userId: number;
  selectedListUuid?: string;
}

const MainComponent: React.FC<MainComponentProps> = ({
  userId,
  selectedListUuid,
}) => {
  const [currentList, setCurrentList] = useState<List | null>(null);
  const {
    items,
    setItems,
    isLoading,
    error,
    fetchInitData,
    fetchItems,
    clearItems,
    createList,
    markActivityState,
    setPremium,
  } = useAPI();

  const [data, setData] = useState<Data | null>(null);
  const [dataError, setDataError] = useState<string | null>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const updateUserData = useCallback((newData: Partial<Data>) => {
    setData((prevData) => (prevData ? { ...prevData, ...newData } : null));
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const initialData = await fetchInitData(userId);
        if (initialData === null) {
          setDataError(
            `Can't get essential data to run app. Try again later, we are already fixing it. userId = ${userId} | ${error}`,
          );
        } else {
          setData(initialData);
          setDataError(null);
        }
      } catch (error) {
        setDataError(
          `Can't get essential data to run app. Try again later, we are already fixing it. ${error}`,
        );
      }
    };

    initializeData();
  }, [userId, fetchInitData]);

  const onUpgrade = async () => {
    setPremium(data?.userData?.chatId ?? 0);
  };

  const handleListClick = useCallback(
    (listUuid: string) => {
      const selectedList = data?.lists.find(
        (list) => list.listUuid === listUuid,
      );
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentList(selectedList || null);
        setIsTransitioning(false);
      }, 300);
    },
    [data?.lists],
  );

  const handleListCreate = useCallback(
    async (listName: string) => {
      try {
        const newList = await createList(
          listName,
          data?.account?.accountUuid ?? '',
          userId,
        );
        if (newList) {
          updateUserData({
            lists: [...(data?.lists || []), newList],
          });
        }
      } catch (error) {
        console.error('Error creating new list:', error);
        // Handle error (e.g., show error message to user)
      }
    },
    [createList, data?.account, updateUserData, data?.lists],
  );

  const handleBack = useCallback(() => {
    setIsTransitioning(true);
    clearItems();
    setTimeout(() => {
      setCurrentList(null);
      setIsTransitioning(false);
    }, 300);
  }, [clearItems]);

  const handleItemClick = useCallback(
    async (itemId: number) => {
      setItems((prevItems: Item[]) =>
        prevItems.map((item) =>
          item.id === itemId ? { ...item, isActive: !item.isActive } : item,
        ),
      );

      try {
        await markItemsActivity([itemId]);
      } catch (error) {
        console.error('Error updating item activity state:', error);
      }
    },
    [setItems],
  );

  const markItemsActivity = async (itemIds: number[]): Promise<void> => {
    try {
      await markActivityState(itemIds);
    } catch (error) {
      // Optionally, revert the state change if the API call fails
    }
  };

  useEffect(() => {
    if (selectedListUuid) {
      handleListClick(selectedListUuid);
    }
  }, [selectedListUuid, handleListClick]);

  useEffect(() => {
    if (currentList) {
      fetchItems(currentList.listUuid);
    }
  }, [currentList, fetchItems]);

  if (dataError) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <p className="text-red-500 p-8 text-center">{dataError}</p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="h-full w-full flex  m-2 p-2 items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="h-screen  w-full overflow-y-auto overflow-x-hidden">
      <div className="w-full max-w-[90%] mx-auto pb-4 pt-12 relative">
        <div
          className={`transition-all duration-300 ease-in-out
            ${
              isTransitioning || currentList
                ? 'transform -translate-x-full opacity-0'
                : 'transform translate-x-0 opacity-100'
            }`}
        >
          <ListContainer
            onUpgrade={onUpgrade}
            accountType={data?.account?.accountType ?? 'free'}
            lists={data?.lists ?? null}
            onListClick={handleListClick}
            onAddList={handleListCreate}
          />
        </div>
        <div
          className={`transition-all duration-300 ease-in-out absolute top-4 left-0 right-0
            ${
              !isTransitioning && currentList
                ? 'transform translate-x-0 opacity-100'
                : 'transform translate-x-full opacity-0'
            }`}
        >
          <ProductItemList
            items={items}
            isLoading={isLoading}
            error={error}
            listName={currentList?.name ?? 'List'}
            onBack={handleBack}
            onItemClick={handleItemClick}
          />
        </div>
      </div>
    </div>
  );
};

export default MainComponent;
