import { Data } from '../types/Data';
import mockedItems from '../mocks/mockedItems.json';
import { Item } from '../types/Item';
import { List } from '../types/List';
import data from '../../node_modules/eslint/node_modules/type-fest/source/readonly-deep.d';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'bypass-tunnel-reminder': 'true',
  'ngrok-skip-browser-warning': 'true',
};

const isLocal = false;

const getHost = function (url: string) {
  if (isLocal) {
    return 'http://localhost:8000/' + url;
    // return 'https://rotten-comics-create.loca.lt/' + url;
  }
  return url;
};

export const markActivityState = async (itemIds: number[]): Promise<void> => {
  const url = getHost(`api/items/switchActive`);

  const response = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({ itemIds }),
  });
  if (!response.ok) {
    throw new Error('Failed to update item activity state');
  }
};

export const removeItems = async (itemIds: number[]): Promise<void> => {
  const url = getHost(`api/items/remove`);

  const response = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({ itemIds }),
  });
  if (!response.ok) {
    throw new Error('Failed to remove items');
  }
};

export const addItem = async (item: Item, userName?: string): Promise<Item> => {
  const url = getHost(`api/items/add`);

  const response = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({
      createdBy: userName,
      listUuid: item.listUuid,
      description: item.description,
    }),
  });
  if (response.status !== 201) {
    throw new Error('Failed to add item');
  }

  return response.json();
};

export const fetchInitData = async (chatId: number): Promise<Data> => {
  const url = getHost('api/getInitData');
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({ chatId }),
    });
    if (!response.ok) {
      throw new Error(
        `HTTP error! status: ${response.status} | body: ${response.body}`,
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

interface ItemsResponse {
  items: Item[];
}

export const fetchItems = async (listUuid: string): Promise<Item[]> => {
  const url = getHost('api/items/getByList');

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({ listUuid }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: ItemsResponse = await response.json();

    // Check if the items property exists and is an array
    if (!data.items || !Array.isArray(data.items)) {
      throw new Error(
        'Invalid response format: items property is missing or not an array',
      );
    }

    return data.items;
  } catch (error) {
    console.error('Error in fetchItems:', error);
    throw error;
  }
};

export const createList = async (
  listName: string,
  accountUuid: string,
  userId: number,
): Promise<List> => {
  const url = getHost(`api/lists/new`);

  const response = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({
      listName,
      accountUuid,
      userId,
    }),
  });

  if (response.status !== 201) {
    throw new Error('Failed to create list');
  }

  const data = await response.json();

  // Map the backend response to the frontend List structure
  const createdList: List = {
    listUuid: data.list.Uuid,
    accountUuid: data.list.AccountUuid,
    name: data.list.Name,
    createdBy: data.list.CreatedBy,
    createdAt: data.list.CreatedAt
      ? new Date(data.list.CreatedAt).toISOString()
      : null,
    isActive: data.list.IsActive,
  };

  return createdList;
};

export const setPremiumClicked = async (userId: number): Promise<void> => {
  const url = getHost(`api/premium`);

  const response = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({ userId }),
  });
  if (!response.ok) {
    throw new Error('Failed to handle premium');
  }
};
