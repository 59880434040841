import React, { useState } from 'react';
import UpgradeToPlusPopup from './UpgradeToPlusPopup';

interface AddNewListItemProps {
  onAddList: (name: string) => void;
  canAddNewList: boolean;
  onUpgrade: () => Promise<void>;
}

const AddNewListItem: React.FC<AddNewListItemProps> = ({
  onAddList,
  canAddNewList,
  onUpgrade,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleAddClick = () => {
    if (!canAddNewList) {
      setShowModal(true);
    } else {
      setIsEditing(true);
    }
  };

  const handleSubmit = () => {
    if (newListName.trim()) {
      onAddList(newListName.trim());
      setNewListName('');
      setIsEditing(false);
    }
  };

  const handleUpgrade = async () => {
    try {
      await onUpgrade();
      setShowModal(false);
    } catch (error) {
      console.error('Upgrade failed:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  if (!isEditing) {
    return (
      <>
        <button
          className={`${
            !canAddNewList
              ? 'bg-[#575757] hover:bg-[#363535]'
              : 'bg-[#FF8000] hover:bg-[#FF9933]'
          } rounded-custom overflow-hidden h-[100px] cursor-pointer relative w-full text-white text-xl font-medium transition-all duration-300 ease-in-out flex items-center justify-between px-6`}
          onClick={handleAddClick}
        >
          {!canAddNewList && (
            <div className="absolute top-0 right-0 bg-gradient-to-r from-purple-500 to-pink-500 text-white text-xs font-bold py-1 px-2 rounded-bl-custom opacity-90 h-[25%] w-[15%] tracking-wider">
              Plus
            </div>
          )}
          <span>Add new list</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1"
            stroke="currentColor"
            className="w-[30%] h-[60%]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </button>
        {showModal && (
          <UpgradeToPlusPopup
            onUpgrade={handleUpgrade}
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  }

  return (
    <div className="bg-white rounded-custom overflow-hidden h-[125px] relative w-full flex items-center p-2 transition-all duration-300 ease-in-out text-text-accent">
      <input
        type="text"
        placeholder="Type list name here..."
        value={newListName}
        onChange={(e) => setNewListName(e.target.value)}
        className="flex-grow text-lg border-b-2 border-[#FF9933] focus:outline-none focus:border-[#FF8000] text-text-accent"
      />
      <button
        onClick={handleSubmit}
        className="ml-4 bg-[#FF9933] text-text-accent w-10 h-10 rounded-full flex items-center justify-center text-2xl font-medium hover:bg-[#FF8000] transition-colors duration-200"
      >
        +
      </button>
    </div>
  );
};

export default AddNewListItem;
