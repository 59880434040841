import React, { useEffect, useState } from 'react';
import MainComponent from './components/MainComponent';
import { Data } from './types/Data';
import userDataMock from './mocks/userDataMock.json';
import { useTelegramWebApp } from './hooks/useTelegramAPI';
import { useAPI } from './hooks/usseAPI';

const typedUserDataMock: Data = userDataMock as Data;

const debug = false;
const App: React.FC = () => {
  var { user, error, initTelegram } = useTelegramWebApp();

  useEffect(() => {
    initTelegram();
  }, []);

  if (debug) {
    return <DebugApp />;
  }

  if (error) {
    /* WARN: for local dev */
    // if (!user) {
    //   user = {
    //     id: 241113238,
    //     first_name: 'testFirstName',
    //     last_name: 'testLastName',
    //     username: 'testUserName',
    //     language_code: 'en',
    //   };
    // }

    return <div>Error: {error}</div>;
  }

  if (!user) {
    return <div background-color="red">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-background text-text flex flex-col">
      <main className="flex-1 overflow-hidden">
        <MainComponent userId={user?.id ?? 0} />
      </main>
    </div>
  );
};

export const DebugApp: React.FC = () => {
  const { user, error, initTelegram } = useTelegramWebApp();
  const [data, setData] = useState<any>({});

  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'bypass-tunnel-reminder': 'true',
    'ngrok-skip-browser-warning': 'true',
  };

  const getHost = function (url: string) {
    if (debug) {
      // return 'http://localhost:8000/' + url;
      return 'https://forty-lights-glow.loca.lt/' + url;
    }
    return url;
  };

  const fetchInitData = async (): Promise<void> => {
    const url = getHost('api/getInitData');

    const newData: any = { start: '+' };
    try {
      newData['fetch'] = '+';
      newData['url'] = url;
      newData['user.id'] = user?.id;

      const response = await fetch(url, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({ chatId: user?.id }),
      });

      newData['resp received'] = '+';
      newData['headers'] = Object.fromEntries(response.headers.entries());
      newData['status'] = response.status;
      newData['statusText'] = response.statusText;

      const responseData = await response.json();
      newData['response'] = responseData;

      setData(newData);
    } catch (error: any) {
      setData({ ...newData, errored: '+', error: error.toString() });
    }
  };

  useEffect(() => {
    initTelegram();
  }, [initTelegram]);

  useEffect(() => {
    if (user) {
      fetchInitData();
    }
  }, [user]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user) {
    return <div style={{ backgroundColor: 'red' }}>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-background text-text w-auto">
      <main className="flex-1 overflow-hidden">
        <MainComponent userId={user.id} />
        <div className="debugInfo text-completed m-4">
          <h1>UserData</h1>
          <h1>{JSON.stringify(user, null, 2)}</h1>
          <h1>Response data</h1>
          <h1>{JSON.stringify(data, null, 2)}</h1>
        </div>
      </main>
    </div>
  );
};
export default App;
