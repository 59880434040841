import React from 'react';
import { Item } from '../../types/Item';
import ProductItem from './ProductItem';
import LoadingBar from './LoadingBar';

interface ItemsListProps {
  items: Item[];
  isLoading: boolean;
  error: string | null;
  listName: string;
  onBack: () => void;
  onItemClick: (itemId: number) => void;
}

const ProductItemList: React.FC<ItemsListProps> = ({
  items,
  isLoading,
  error,
  listName,
  onBack,
  onItemClick,
}) => {
  if (isLoading) return <p>Loading items...</p>;
  if (error) return <p className="error">{error}</p>;
  return (
    <div className="product-item-list w-full h-[90vh] flex flex-col px-4 overflow-hidden">
      <div className="flex items-center mb-4 -ml-2">
        <button
          onClick={onBack}
          className="back-button mr-2 p-2 rounded-full hover:bg-gray-200 transition-colors flex-shrink-0"
          aria-label="Go back"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
            />
          </svg>
        </button>
        <div className="flex-grow">
          <LoadingBar items={items} />
        </div>
      </div>
      <h1 className="text-xl font-medium mb-4">{listName}</h1>
      <div className="flex-grow overflow-y-auto pb-8 h-full">
        {items.length === 0 && <p>No items to display.</p>}
        {items.length > 0 && (
          <ul className="items-list space-y-2">
            {items.map((item) => (
              <li key={item.id} className="ProductItemElement">
                <ProductItem
                  item={item}
                  onClick={onItemClick}
                  isActive={item.isActive}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ProductItemList;
