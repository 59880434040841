// telegramWebApp.ts

import WebApp from '@twa-dev/sdk';
import { useState } from 'react';

export interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  language_code?: string;
}

export interface WebAppData {
  user: TelegramUser | null;
  setUser: React.Dispatch<React.SetStateAction<TelegramUser | null>>;
  error: string | null;
  initTelegram: () => void;
}

export const useTelegramWebApp = (): WebAppData => {
  const [user, setUser] = useState<TelegramUser | null>(null);
  const [error, setError] = useState<string | null>(null);

  const initTelegram = (): void => {
    try {
      WebApp.ready();

      if (!WebApp.initDataUnsafe) {
        throw new Error('Telegram Web App initDataUnsafe is missing');
      }

      const userData = WebApp.initDataUnsafe.user;
      if (!userData || !userData.id) {
        throw new Error('User data is missing from initDataUnsafe');
      }

      setUser(userData as TelegramUser);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'An unknown error occurred',
      );
    }
  };

  return { user, setUser, error, initTelegram };
};
