import React from 'react';
import { Item } from '../../types/Item';

interface ItemProps {
  item: Item;
  onClick: (itemId: number) => void;
  isActive: boolean;
}

const ProductItem: React.FC<ItemProps> = ({ item, onClick, isActive }) => {
  return (
    <li
      className="item bg-completed rounded-custom overflow-hidden h-[calc(100%/8)] min-h-[55px] cursor-pointer relative w-full flex items-center px-4"
      onClick={() => onClick(item.id)}
    >
      <div
        className={`absolute left-0 top-0 h-full bg-button-background transition-all duration-300 ease-in-out ${
          isActive ? 'w-full' : 'w-0'
        }`}
      ></div>
      <span className="grow item-description w-full text-left line-clamp-2 z-10 relative">
        {item.description}
      </span>
      <span
        className={`flex-shrink-0 ml-2 transition-all duration-300 ease-in-out ${
          isActive ? 'opacity-0 translate-x-full' : 'opacity-100 translate-x-0'
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </span>
    </li>
  );
};

export default ProductItem;
