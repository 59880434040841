import React from 'react';
import { List } from '../../types/List';

interface ListProps {
  list: List;
  onClick: (listUuid: string) => void;
}

const ListItem: React.FC<ListProps> = ({ list, onClick }) => {
  return (
    <section
      className="bg-button-background rounded-custom overflow-hidden h-[125px] cursor-pointer relative pl-2 pr-1 py-4 shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
      onClick={() => onClick(list.listUuid)}
    >
      <div className="flex justify-between h-full">
        {/* Left side: List name */}
        <h3 className="text-xl font-light truncate pr-4 textColor-bg-button-text flex-grow">
          {list.name}
        </h3>

        {/* Right side: Arrow and Cart icons */}
        <div className="flex flex-col items-center justify-between">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-5 h-5 text-background flex-shrink-0 drop-shadow -mb-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
          <div className="flex-grow" /> {/* Spacer */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="0.5"
            stroke="currentColor"
            className="w-12 h-12 text-background opacity-95 drop-shadow-lg -mb-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default ListItem;
